export const LOCAL_IMPORT_MAP = {
  'toto-core-agency': 'http://localhost:9204/totoplay-toto-core-agency.js',
  'toto-core-angbao': 'http://localhost:3016/totoplay-toto-core-angbao.js',
  'toto-core-chat': 'http://localhost:9202/totoplay-toto-core-chat.js',
  'toto-core-external-games': 'http://localhost:9207/totoplay-toto-core-external-games.js',
  'toto-core-leaderboard': 'http://localhost:3013/totoplay-toto-core-leaderboard.js',
  'toto-core-live-games': 'http://localhost:3019/totoplay-toto-core-live-games.js',
  'toto-core-livestream': 'http://localhost:3015/totoplay-toto-core-livestream.js',
  'toto-core-lottery': 'http://localhost:9206/totoplay-toto-core-lottery.js',
  'toto-core-payment': 'http://localhost:9205/totoplay-toto-core-payment.js',
  'toto-core-permission': 'http://localhost:3018/totoplay-toto-core-permission.js',
  'toto-core-pk3': 'http://localhost:9203/totoplay-toto-core-pk3.js',
  'toto-core-promotion': 'http://localhost:9201/totoplay-toto-core-promotion.js',
  'toto-core-publisher': 'http://localhost:12000/totoplay-toto-core-publisher.js',
  'toto-core-report': 'http://localhost:12500/totoplay-toto-core-report.js',
  'toto-core-socket': 'http://localhost:3017/totoplay-toto-core-socket.js',
  'toto-core-system': 'http://localhost:3020/totoplay-toto-core-system.js',
  'toto-core-translator': 'http://localhost:9903/totoplay-toto-core-translator.js',
  'toto-core-ui': 'http://localhost:9006/totoplay-toto-core-ui.js',
  'toto-core-utilities': 'http://localhost:9003/totoplay-toto-core-utilities.js',
  'toto-home': 'http://localhost:9001/totoplay-toto-home.js',
  'toto-login': 'http://localhost:9004/totoplay-toto-login.js',
  'toto-lottery': 'http://localhost:9008/totoplay-toto-lottery.js',
  'toto-profile': 'http://localhost:9002/totoplay-toto-profile.js',
  'toto-promotion': 'http://localhost:9007/totoplay-toto-promotion.js',
  'toto-register': 'http://localhost:9005/totoplay-toto-register.js',
  'toto-wui-admin-account': 'http://localhost:12700/totoplay-toto-wui-admin-account.js',
  'toto-wui-admin-app': 'http://localhost:11100/totoplay-toto-wui-admin-app.js',
  'toto-wui-admin-chat': 'http://localhost:11800/totoplay-toto-wui-admin-chat.js',
  'toto-wui-admin-core-ui': 'http://localhost:3014/totoplay-toto-wui-admin-core-ui.js',
  'toto-wui-admin-dashboard': 'http://localhost:11400/totoplay-toto-wui-admin-dashboard.js',
  'toto-wui-admin-game': 'http://localhost:12100/totoplay-toto-wui-admin-game.js',
  'toto-wui-admin-livestream': 'http://localhost:11200/totoplay-toto-wui-admin-livestream.js',
  'toto-wui-admin-profile': 'http://localhost:12200/totoplay-toto-wui-admin-profile.js',
  'toto-wui-admin-publisher': 'http://localhost:11900/totoplay-toto-wui-admin-publisher.js',
  'toto-wui-admin-report': 'http://localhost:12400/totoplay-toto-wui-admin-report.js',
  'toto-wui-admin-system': 'http://localhost:12300/totoplay-toto-wui-admin-system.js',
  'toto-wui-admin-ui-config': 'http://localhost:11500/totoplay-toto-wui-admin-ui-config.js',
  'toto-wui-angbao': 'http://localhost:11600/totoplay-toto-wui-angbao.js',
  'toto-wui-authen': 'http://localhost:11300/totoplay-toto-wui-authen.js',
  'toto-wui-footer': 'http://localhost:9014/totoplay-toto-wui-footer.js',
  'toto-wui-game-lobby': 'http://localhost:9016/totoplay-toto-wui-game-lobby.js',
  'toto-wui-header': 'http://localhost:9013/totoplay-toto-wui-header.js',
  'toto-wui-leader-board': 'http://localhost:9017/totoplay-toto-wui-leader-board.js',
  'toto-wui-live-games': 'http://localhost:13100/totoplay-toto-wui-live-games.js',
  'toto-wui-lucky-wheel': 'http://localhost:9018/totoplay-toto-wui-lucky-wheel.js',
  'toto-wui-pk3': 'http://localhost:9012/totoplay-toto-wui-pk3.js',
  'toto-wui-plugin-common': 'http://localhost:9900/totoplay-toto-wui-plugin-common.js',
  'toto-wui-plugin-download': 'http://localhost:9901/totoplay-toto-wui-plugin-download.js',
  'toto-wui-plugin-games': 'http://localhost:9902/totoplay-toto-wui-plugin-games.js',
  'toto-wui-plugin-widget': 'http://localhost:9904/totoplay-toto-wui-plugin-widget.js',
  'toto-wui-seo-pages': 'http://localhost:13000/totoplay-toto-wui-seo-pages.js',
  'toto-wui-sport-lobby': 'http://localhost:9020/totoplay-toto-wui-sport-lobby.js',
  'toto-wui-static-pages': 'http://localhost:9015/totoplay-toto-wui-static-pages.js',
  'toto-wui-super-vip': 'http://localhost:9019/totoplay-toto-wui-super-vip.js',
  'toto-wui-plugin-angbao': 'http://localhost:11700/totoplay-toto-wui-plugin-angbao.js',
};
