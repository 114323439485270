import './theme/style.scss';

import { registerApplication, start } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { CoreFeatureGA } from '@totopkg/shared-feature-ga';

import layout79sodo from './layouts/79sodo.layout.html';
import layout88iwin from './layouts/88iwin.layout.html';
import layoutAnto247Register from './layouts/anto247-register.layout.html';
import layoutAnto247 from './layouts/anto247.layout.html';
import layoutIndoWin69 from './layouts/indowin69.layout.html';
import layoutKhobau68 from './layouts/khobau68.layout.html';
import layoutMiso88 from './layouts/miso88.layout.html';
import layoutPetaballAdmin from './layouts/petaball-admin.layout.html';
import layoutPetaball from './layouts/petaball.layout.html';
import layoutTydo88 from './layouts/tydo88.layout.html';
import layoutVn9 from './layouts/vn9.layout.html';
import layoutVuabet from './layouts/vuabet.layout.html';
import layoutTh777 from './layouts/th777.layout.html';
import layoutZbg from './layouts/zbg.layout.html';
import layoutVn8 from './layouts/vn8.layout.html';

import { checkAndLoadImportMapsByApp } from './scripts/check-import-maps-by-app';

import layoutCockFight from './layouts/cockfight.layout.html';
import layoutPp9vn from './layouts/pp9vn.layout.html';
import layoutRegister from './layouts/register.layout.html';
import layoutSlotSite from './layouts/slot-site.layout.html';
import layoutTotoLiveAdmin from './layouts/totolive-admin.layout.html';
import layoutTotoLive from './layouts/totolive.layout.html';
import layoutPetaLive from './layouts/petalive.layout.html';
import * as Sw from './serviceWorkerRegistration';

const getLayout = () => {
  switch (process.env.LAYOUT_PATH) {
    case './layouts/79sodo.layout.html':
      return layout79sodo;

    case './layouts/khobau68.layout.html':
      return layoutKhobau68;

    case './layouts/vuabet.layout.html':
      return layoutVuabet;

    case './layouts/petaball.layout.html':
      return layoutPetaball;

    case './layouts/petaball-admin.layout.html':
      return layoutPetaballAdmin;

    case './layouts/vn9.layout.html':
      return layoutVn9;

    case './layouts/anto247.layout.html':
      return layoutAnto247;

    case './layouts/anto247-register.layout.html':
      return layoutAnto247Register;

    case './layouts/88iwin.layout.html':
      return layout88iwin;

    case './layouts/tydo88.layout.html':
      return layoutTydo88;

    case './layouts/miso88.layout.html':
      return layoutMiso88;

    case './layouts/indowin69.layout.html':
      return layoutIndoWin69;

    case './layouts/slot-site.layout.html':
      return layoutSlotSite;

    case './layouts/register.layout.html':
      return layoutRegister;

    case './layouts/pp9vn.layout.html':
      return layoutPp9vn;

    case './layouts/totolive.layout.html':
      return layoutTotoLive;

    case './layouts/totolive-admin.layout.html':
      return layoutTotoLiveAdmin;

    case './layouts/cockfight.layout.html':
      return layoutCockFight;

    case './layouts/th777.layout.html':
      return layoutTh777;

    case './layouts/petalive.layout.html':
      return layoutPetaLive;

    case './layouts/zbg.layout.html':
      return layoutZbg;

    case './layouts/vn8.layout.html':
      return layoutVn8;
    default:
      return '';
  }
};

function startSpa() {
  const layout = getLayout().replace(
    '<single-spa-router>',
    `<single-spa-router base="${process.env.REACT_APP_BASE_HREF}/">`,
  );
  const routes = constructRoutes(layout);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
}

checkAndLoadImportMapsByApp().then(() => {
  startSpa();

  if (!process.env.isLocal) {
    CoreFeatureGA.initGoogleAnalytic({ delay: 5000 });
    Sw.register();
  }
});
